import React, { ChangeEvent, useEffect, useState, lazy, Suspense } from "react";
import { navigate } from "gatsby";
import { toast } from "react-toastify";

import { Container, GridContainer, Input, P, Separator, TextArea, A } from "@util/standard";
import {
  AppLayout,
  EmailSelectionModal,
  SignatureModal,
  ProductOptions,
  InputField,
} from "@shared/salesTool";
import { ParentWrapper } from "@shared/salesTool/components.styles";
import { Button, CheckBox, Dropdown, ModalBox } from "@global";
import {
  useDeleteDocumentOnFirebase,
  useDownloadUrlFromStorage,
  useSelectCustomer,
  useUpdateOrderToFirestore,
} from "@util/firebaseHooks";
import { useStore } from "@state/store";
import { isBrowser, orderPriceString } from "@util/helper";
import { appPaths, pricingLink } from "@util/constants";
import {
  useUpdatePackagingOptions,
  useSyncAdCopyFromCache,
  useMixAndMatchOptions,
  useUpdateMixMatchToOrder,
} from "@util/hooks";
import { ConfirmDeleteOrderModal } from "./create-order";
import { MixAndMatchState, Order, Packaging } from "@state/types";
import { SelectOption } from "@util/types";
import CreateCustomerForm from "@components/forms/createCustomer";

const OrderPdf = lazy(() => import("@shared/salesTool/orderPdf"));

const defaultPackaging = { price: undefined, quantity: undefined };

export default function EditOrder() {
  if (!isBrowser()) return null;
  const { order, setOrder, adCopy, selectedCustomer, setTemplateAvailable } = useStore();
  const handleRightButtonClick = () => navigate(appPaths.orders);

  if (!order) {
    return (
      <AppLayout currentPath="editOrder" onRightButtonClick={handleRightButtonClick}>
        <ParentWrapper>
          <h1>No order selected</h1>
        </ParentWrapper>
      </AppLayout>
    );
  }

  const { id, customerId, product, hasSent, displayOrderID } = order;

  //state
  const [pdfBlob, setPdfBlob] = useState<Blob>();
  const [formData, setFormData] = useState(order);
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [sendModalVisible, setSendModalVisible] = useState(false);
  const [signatureVisible, setSignatureVisible] = useState(false);
  const [editCustomerVisible, setEditCustomerVisible] = useState(false);
  const [addedOptions, setAddedOptions] = useState<Packaging[]>(
    order.packaging ? [...order.packaging] : [defaultPackaging],
  );
  const [mixAndMatch, setMixAndMatch] = useState<Partial<Order>[]>(order.mixAndMatch ?? []);
  const [mixAndMatchSelectedOption, setMixAndMatchSelectedOption] = useState<SelectOption>();

  // hooks
  useSyncAdCopyFromCache(id, product?.productId);
  useSelectCustomer(customerId);
  useUpdatePackagingOptions(addedOptions, setFormData);
  useUpdateMixMatchToOrder(mixAndMatch, setFormData);

  useEffect(() => {
    setTemplateAvailable({
      returned: false,
      available: false,
      pickerSelected: false,
      templates: null,
    });
  }, []);

  const [mixAndMatchOptions, mixAndMatchOptionsLoading] = useMixAndMatchOptions();
  const [loading, handleUpdateOrder] = useUpdateOrderToFirestore(id, formData, () => {
    toast.success("Changes saved to order");
  });
  const [deleteLoading, handleDelete] = useDeleteDocumentOnFirebase("orders", id, () => {
    setConfirmVisible(false);
    navigate(appPaths.orders);
  });
  const downloadUrl = useDownloadUrlFromStorage(hasSent);

  // functions
  const handleAddAnotherCalendar = () => {
    if (mixAndMatchSelectedOption == null) {
      toast.error("Please select a mix and match option");
      return;
    }

    const { id, title } = mixAndMatchSelectedOption;
    const calendarToAdd = {
      product: { productId: id, productName: title, mixMatch: true },
      price: order.price,
    };

    setMixAndMatchSelectedOption(undefined);
    if (mixAndMatch.length) {
      setMixAndMatch(prev => [...prev, calendarToAdd]);
      return;
    }
    setMixAndMatch([calendarToAdd]);
  };

  const validateOrder = () => {
    if (!formData.quantity) {
      toast.error("Please enter Calendar quantity");
      return;
    }
    if (!formData.price) {
      toast.error("Please enter Calendar price");
      return;
    }
    if (formData.season === "") {
      toast.error("Please enter a Season");
      return;
    }
    if (!formData.hasAgreedToTerms) {
      toast.error("Please agree to the terms and conditions");
      return;
    }
    return true;
  };

  const handleOpenSignModal = () => {
    if (!validateOrder()) return;
    handleUpdateOrder(true);
    setSignatureVisible(true);
  };

  const handleSendClick = () => {
    if (!validateOrder()) return;
    if (!Boolean(adCopy?.signature)) {
      setSignatureVisible(true);
      toast.error("Please sign the order before sending");
      return;
    }
    setSendModalVisible(true);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSelectProductClick = () => {
    if (order == null) return;
    setOrder(order);
    navigate("/calendars");
  };

  const handleEditCustomerClick = () => {
    setEditCustomerVisible(true);
  };

  return (
    <AppLayout currentPath="editOrder" onRightButtonClick={handleRightButtonClick}>
      <ParentWrapper allReadOnly={hasSent}>
        <Container flexDirection="column" margin="0 0 100px 0">
          <Container margin="0 0 20px 0" width="100%">
            <h2>{`Order ID: ${displayOrderID ?? id}`}</h2>
            <Button
              theme="text"
              margin="0 0 0 20px"
              linkText="Delete order"
              loading={deleteLoading}
              onClick={() => setConfirmVisible(true)}
            />
          </Container>
          <GridContainer repeat={2} tabletRepeat={2} columnGap={60}>
            <GridContainer repeat={1} tabletRepeat={1} rowGap={20} height="fit-content">
              <TextArea
                name="notes"
                placeholder="Order notes"
                onChange={handleInputChange}
                value={formData.notes}
              />
              {selectedCustomer && (
                <InputField label="Customer">
                  <Container width="100%">
                    <Input
                      bordered
                      readOnly
                      value={`${selectedCustomer.company} - ${selectedCustomer.name}`}
                    />
                    <Button
                      linkText="Edit Customer"
                      margin="0 0 0 15px"
                      onClick={handleEditCustomerClick}
                    />
                  </Container>
                </InputField>
              )}
              <InputField label="Season">
                <Input
                  name="season"
                  placeholder="Enter season"
                  onChange={handleInputChange}
                  value={formData.season}
                />
              </InputField>
              <InputField label="Product code">
                <Input
                  name="productCodeOverride"
                  placeholder="New code"
                  onChange={handleInputChange}
                  value={formData.productCodeOverride ?? product?.productId!}
                />
              </InputField>
              <InputField label="Purchase order number">
                <Input
                  name="purchaseOrderNumber"
                  placeholder="Order number"
                  onChange={handleInputChange}
                  value={formData.purchaseOrderNumber}
                />
              </InputField>
              <Container flexDirection="column">
                <CheckBox
                  disabled={hasSent}
                  label="Agree with terms and conditions"
                  isChecked={formData.hasAgreedToTerms}
                  onChange={() =>
                    setFormData({ ...formData, hasAgreedToTerms: !formData.hasAgreedToTerms })
                  }
                />
                <CheckBox
                  disabled={hasSent}
                  label="Proceed to production"
                  isChecked={formData.hasViewedProofOfFinal}
                  onChange={() =>
                    setFormData({
                      ...formData,
                      hasViewedProofOfFinal: !formData.hasViewedProofOfFinal,
                    })
                  }
                />
              </Container>

              <Container margin="20px 0 0 0" width="100%" columnGap={20}>
                {hasSent ? (
                  <Button externalLink={downloadUrl}>Download Order</Button>
                ) : (
                  <Button linkText="Save Changes" loading={loading} onClick={handleUpdateOrder} />
                )}
                {product && !hasSent && (
                  <Button
                    theme="bordered"
                    linkText="Edit calendar ad"
                    onClick={() => navigate(appPaths.editor)}
                  />
                )}
                {product == null && (
                  <Button
                    margin="20px 0 0 0"
                    linkText="Select product"
                    theme="bordered"
                    onClick={handleSelectProductClick}
                  />
                )}
              </Container>
            </GridContainer>

            <Container flexDirection="column" rowGap={40}>
              <InputField hasDollarSign label="Shipping cost">
                <Input
                  name="shippingCost"
                  type="number"
                  placeholder="Price"
                  onChange={handleInputChange}
                  value={formData.shippingCost}
                />
              </InputField>
              <Separator height="0" />
              <A underlined href={pricingLink} target="_blank" width="fit-content">
                Pricing list
              </A>
              <ProductOptions
                formData={formData}
                hasSent={hasSent}
                packagingOptionsState={[addedOptions, setAddedOptions]}
                handleInputChange={handleInputChange}
              />
              <MixAndMatch state={[mixAndMatch, setMixAndMatch]} hasSent={hasSent} />
              {product?.mixMatch && !hasSent && mixAndMatchOptions && (
                <Container flexDirection="column">
                  <h3>Pick another calendar design</h3>
                  <Container>
                    <Dropdown
                      name="mixAndMatch"
                      defaultTitle="Mix and match"
                      options={mixAndMatchOptions}
                      optionState={[mixAndMatchSelectedOption, setMixAndMatchSelectedOption]}
                      disabled={mixAndMatchOptionsLoading}
                    />
                    <Button
                      margin="0 0 0 15px"
                      width="250px"
                      linkText="Add calendar"
                      onClick={handleAddAnotherCalendar}
                    />
                  </Container>
                </Container>
              )}

              {adCopy && !hasSent && (
                <Container>
                  <Button linkText="Update PDF" onClick={() => handleUpdateOrder(true)} />
                  <Button
                    linkText="Sign Order"
                    theme="bordered"
                    onClick={handleOpenSignModal}
                    margin="0 0 0 20px"
                  />
                </Container>
              )}
              {hasSent && order.totalPrice && (
                <div>
                  <P margin="0" fontSize={20}>
                    Total Price
                  </P>
                  <h2>{orderPriceString(order.totalPrice)}</h2>
                </div>
              )}
            </Container>
          </GridContainer>
        </Container>

        {/* Pdf Display */}
        {adCopy && (
          <Suspense fallback={<div>Loading...</div>}>
            <OrderPdf handleSendClick={handleSendClick} blobState={[pdfBlob, setPdfBlob]} />
          </Suspense>
        )}
      </ParentWrapper>

      <SignatureModal state={[signatureVisible, setSignatureVisible]} />
      <ConfirmDeleteOrderModal
        loading={deleteLoading}
        onConfirm={handleDelete}
        state={[confirmVisible, setConfirmVisible]}
      />
      {order && (
        <EmailSelectionModal
          state={[sendModalVisible, setSendModalVisible]}
          pdfBlob={pdfBlob}
          orderId={id}
        />
      )}
      <ModalBox visible={editCustomerVisible} setVisible={setEditCustomerVisible}>
        <CreateCustomerForm customer={selectedCustomer} />
      </ModalBox>
    </AppLayout>
  );
}

interface MixAndMatchProps {
  state: MixAndMatchState;
  hasSent: boolean;
}

const MixAndMatch = ({ state, hasSent }: MixAndMatchProps) => {
  if (!state[0].length) return null;
  const [mixAndMatch, setMixAndMatch] = state;

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    arrayIndex: number,
  ) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    const updatedMixAndMatch = mixAndMatch.map((item, index) => {
      if (index === arrayIndex) {
        return { ...item, [name]: value };
      }
      return item;
    });
    setMixAndMatch(updatedMixAndMatch);
  };

  return (
    <Container flexDirection="column" rowGap={40}>
      {mixAndMatch &&
        mixAndMatch.map((order, index) => {
          return (
            <ProductOptions
              key={index}
              formData={order}
              hasSent={hasSent}
              mixAndMatchState={{ index, state: [mixAndMatch, setMixAndMatch] }}
              handleInputChange={event => handleInputChange(event, index)}
            />
          );
        })}
    </Container>
  );
};
